<template>
  <div class="task-view-page" v-loading="loading">
    <div class="filter-section">

    </div>

    <div class="filter-section">
      <h3>Statistic for videos</h3>
      <div style="margin-top: 16px">
        <el-radio-group style="margin-right: 22px" v-model="videoTypeFilter" @change="applyFilters">
          <el-radio-button label="all">All videos</el-radio-button>
          <el-radio-button label="non_scam">Only trust</el-radio-button>
          <el-radio-button label="scam">Only scam</el-radio-button>
        </el-radio-group>
        <el-date-picker
            v-model="dateRangeForStats"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            @change="applyFilters"
        />

      </div>
    </div>

    <!-- Статистика числовая -->
    <div class="statistic">
      <div class="static-li">
        <strong>{{ filteredStatistics.unique_users_count || '-' }}</strong>
        <span>Unique publishers</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredStatistics.publish_count || '-' }}</strong>
        <span>Total videos published</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredStatistics.unpublish_count || '-' }}</strong>
        <span>Total videos unpublished</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredStatistics.failed_count || '-' }}</strong>
        <span>Total failed videos</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredStatistics.avgVideosPerUser ? parseFloat(Number(filteredStatistics.avgVideosPerUser).toFixed(4)) : '-' }}</strong>
        <span>Avg publish videos per user</span>
      </div>
      <div class="static-li">
        <strong>{{ filteredStatistics.avgViewsPerVideo ? parseFloat(Number(filteredStatistics.avgViewsPerVideo).toFixed(4)) : '-' }}</strong>
        <span>Avg views per publish video</span>
      </div>
    </div>

    <!-- Фильтр по диапазону дат для Бар-чарта (количество пользователей по видео) -->
    <div class="filter-section">
      <h3>Published videos for a specific period</h3>
      <el-date-picker
          style="margin-top: 16px"
          v-model="barChartDateRange"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="applyFilters"
      />
    </div>

    <!-- Бар-чарт -->
    <canvas class="barChart" id="usersVideosBarChart" style="margin-top: 20px;"></canvas>

    <!-- Фильтр по диапазону дат для Линейного графика (количество видео в день) -->
    <div class="filter-section" style="margin-top: 32px">
      <h3>Number of users by the number of videos published per day</h3>
      <el-date-picker
          style="margin-top: 16px"
          v-model="lineChartDateRange"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="applyFilters"
      />
    </div>

    <!-- Линейный график -->
    <canvas class="videosChart" id="videosCountLineChart" style="margin-top: 20px;"></canvas>

    <!-- График динамики публикаций видео (обычные и скам) -->
    <div class="filter-section" style="margin-top: 32px">
      <h3>Overall dynamics of published videos</h3>
      <el-date-picker
          style="margin-top: 16px"
          v-model="allLineChartDateRange"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="applyFilters"
      />
    </div>
    <canvas  class="videosChart" id="scamVsNonScamChart" style="margin-top: 20px;"></canvas>
  </div>
</template>

<script>
import {onMounted, ref, onBeforeUnmount} from "vue";
import {db} from "@/firebase/init"; // Инициализация Firebase
import {collection, getDocs} from "firebase/firestore";
import Chart from "chart.js/auto"; // Chart.js для создания графиков

export default {
  setup() {
    // Данные для статистики и графиков
    const rawVideosData = ref([]); // Все данные из Firebase
    const filteredStatistics = ref({});
    const dateRangeForStats = ref([]); // Фильтр по дате для статистики
    const barChartDateRange = ref([]); // Фильтр по дате для Бар-чарта
    const lineChartDateRange = ref([]); // Фильтр по дате для Линейного графика
    const allLineChartDateRange = ref([])
    const videoTypeFilter = ref("all"); // Фильтр для типа видео (все, нормальные или скамные)

    const loading = ref(true);

    // Ссылки на графики, чтобы их можно было уничтожить перед пересозданием
    let barChartInstance = null;
    let lineChartInstance = null;
    let scamVsNonScamChartInstance = null;

    // Уничтожение графиков перед пересозданием
    const destroyBarChart = () => {
      if (barChartInstance) {
        barChartInstance.destroy();
        barChartInstance = null;
      }
    };

    const destroyLineChart = () => {
      if (lineChartInstance) {
        lineChartInstance.destroy();
        lineChartInstance = null;
      }
    };

    const destroyScamVsNonScamChart = () => {
      if (scamVsNonScamChartInstance) {
        scamVsNonScamChartInstance.destroy();
        scamVsNonScamChartInstance = null;
      }
    };

    // Функция для получения всех данных из Firebase
    const fetchData = async () => {
      loading.value = true;

      const videosQuery = collection(db, "videos");
      const queryVideosSnapshot = await getDocs(videosQuery);
      rawVideosData.value = queryVideosSnapshot.docs.map((dVideo) => ({
        ...dVideo.data(),
        createTime: dVideo.data().createTime * 1000,
      }));

      loading.value = false;
      applyFilters()
    };

    // Функция для фильтрации данных на фронте
    const applyFilters = () => {
      // Фильтрация данных для статистики
      const filteredStatsVideos = filterVideosByDate(rawVideosData.value, dateRangeForStats.value);

      // Вычисление статистики
      calculateStatistics(filteredStatsVideos);

      // Фильтрация и обновление графиков
      updateBarChart();
      updateLineChart();
      updateScamVsNonScamChart();
    };

    // Функция для фильтрации данных по диапазону дат
    const filterVideosByDate = (videos, dateRange, isScam = null, ignore_publish = null) => {
      if (!dateRange || dateRange.length === 0) {
        return videos;
      }

      const [startDate, endDate] = dateRange;

      if (isScam === null || isScam === 'all') {
        if(!ignore_publish) {
          return videos.filter(
              (video) =>
                  video.status === 'PUBLISH_COMPLETE' &&
                  video.createTime >= new Date(startDate).getTime() &&
                  video.createTime <= new Date(endDate).getTime());
        } else {
          return videos.filter(
              (video) =>
                  video.createTime >= new Date(startDate).getTime() &&
                  video.createTime <= new Date(endDate).getTime());
        }
      } else if (isScam === true || isScam === 'scam') {

        return videos.filter(
            (video) =>
                video.createTime >= new Date(startDate).getTime() &&
                video.createTime <= new Date(endDate).getTime() && video.is_scam === true)
      } else {

        return videos.filter(
            (video) =>
                video.createTime >= new Date(startDate).getTime() &&
                video.createTime <= new Date(endDate).getTime() && video.is_scam !== true)
      }
    };

    // Функция для вычисления статистики
    const calculateStatistics = (videos) => {
      let usersVideosCount = {};
      let totalViews = 0;
      let totalVideos = 0;
      let totalVideosUnpublish = 0;
      let totalFailed = 0;
      let totalCount = 0;

      let videosFo = videos;

      if (videoTypeFilter.value === 'scam') {
        videosFo = videos.filter(({is_scam}) => is_scam)
      }

      if (videoTypeFilter.value === 'non_scam') {
        videosFo = videos.filter(({is_scam}) => !is_scam)
      }

      videosFo.forEach((video) => {
        const userId = video.userId;

        // Подсчитываем количество видео для каждого пользователя

        totalViews += Number(video.view_count) || 0;

        totalCount++;
        if(video.status === 'PUBLISH_COMPLETE') {
          totalVideos++;

          if (usersVideosCount[userId]) {
            usersVideosCount[userId]++;
          } else {
            usersVideosCount[userId] = 1;
          }
        }

        if(video.status !== 'PUBLISH_COMPLETE') {
          if(video.status === 'FAILED') {
            totalFailed ++;
          } else {
            totalVideosUnpublish ++
          }
        }
      });

      // Подсчёт пользователей, опубликовавших разное количество видео
      let usersWith1Video = 0;
      let usersWith2Videos = 0;
      let usersWith3Videos = 0;
      let usersWith4Videos = 0;
      let usersWith5Videos = 0;
      let usersWithMoreThan5Videos = 0;

      Object.values(usersVideosCount).forEach((count) => {
        if (count === 1) usersWith1Video++;
        if (count === 2) usersWith2Videos++;
        if (count === 3) usersWith3Videos++;
        if (count === 4) usersWith4Videos++;
        if (count === 5) usersWith5Videos++;
        if (count > 5) usersWithMoreThan5Videos++;
      });

      filteredStatistics.value = {
        publish_count: totalVideos,
        unpublish_count: totalVideosUnpublish,
        failed_count: totalFailed,
        total_count:  totalCount,
        unique_users_count: Object.keys(usersVideosCount).length,
        usersWith1Video,
        usersWith2Videos,
        usersWith3Videos,
        usersWith4Videos,
        usersWith5Videos,
        usersWithMoreThan5Videos,
        avgVideosPerUser: totalVideos / (Object.keys(usersVideosCount).length || 1),
        avgViewsPerVideo: totalVideos > 0 ? (totalViews / totalVideos).toFixed(2) : 0, // Исправлено деление на ноль
      };
    };

    // Функция для обновления Бар-чарта
    const updateBarChart = () => {
      destroyBarChart(); // Уничтожаем только Бар-чарт перед пересозданием

      const filteredBarChartVideos = filterVideosByDate(rawVideosData.value, barChartDateRange.value);
      let usersVideosCount = {};

      // Подсчёт количества видео для каждого пользователя
      filteredBarChartVideos.forEach((video) => {
        const userId = video.userId;
        if (usersVideosCount[userId]) {
          usersVideosCount[userId]++;
        } else {
          usersVideosCount[userId] = 1;
        }
      });

      // Переменные для хранения количества пользователей в каждой категории
      let usersWith1Video = 0;
      let usersWith2Videos = 0;
      let usersWith3Videos = 0;
      let usersWith4Videos = 0;
      let usersWith5Videos = 0;
      let usersWith6To10Videos = 0;
      let usersWith11To20Videos = 0;
      let usersWith21To30Videos = 0;
      let usersWith31To50Videos = 0;
      let usersWith51To100Videos = 0;
      let usersWithMoreThan100Videos = 0;

      // Разбиваем пользователей по категориям
      Object.values(usersVideosCount).forEach((count) => {
        if (count === 1) usersWith1Video++;
        if (count === 2) usersWith2Videos++;
        if (count === 3) usersWith3Videos++;
        if (count === 4) usersWith4Videos++;
        if (count === 5) usersWith5Videos++;
        if (count >= 6 && count <= 10) usersWith6To10Videos++;
        if (count >= 11 && count <= 20) usersWith11To20Videos++;
        if (count >= 21 && count <= 30) usersWith21To30Videos++;
        if (count >= 31 && count <= 50) usersWith31To50Videos++;
        if (count >= 51 && count <= 100) usersWith51To100Videos++;
        if (count > 100) usersWithMoreThan100Videos++;
      });

      // Данные для бар-чарта
      const barChartData = {
        labels: [
          "1 video",
          "2 videos",
          "3 videos",
          "4 videos",
          "5 videos",
          "6-10 videos",
          "11-20 videos",
          "21-30 videos",
          "31-50 videos",
          "51-100 videos",
          "100+ videos",
        ],
        datasets: [
          {
            label: "Number of Users",
            data: [
              usersWith1Video,
              usersWith2Videos,
              usersWith3Videos,
              usersWith4Videos,
              usersWith5Videos,
              usersWith6To10Videos,
              usersWith11To20Videos,
              usersWith21To30Videos,
              usersWith31To50Videos,
              usersWith51To100Videos,
              usersWithMoreThan100Videos,
            ],
            backgroundColor: [
              "rgba(75, 192, 192, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
              "rgba(255, 99, 132, 0.6)",
              "rgba(50, 205, 50, 0.6)",
              "rgba(255, 140, 0, 0.6)",
              "rgba(30, 144, 255, 0.6)",
              "rgba(123, 104, 238, 0.6)",
              "rgba(255, 69, 0, 0.6)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(50, 205, 50, 1)",
              "rgba(255, 140, 0, 1)",
              "rgba(30, 144, 255, 1)",
              "rgba(123, 104, 238, 1)",
              "rgba(255, 69, 0, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      // Рендеринг бар-чарта
      const barCtx = document.getElementById("usersVideosBarChart").getContext("2d");
      barChartInstance = new Chart(barCtx, {
        type: "bar",
        data: barChartData,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 500, // Настройка шага для оси Y
              },
            },
          },
        },
      });
    };

    // Функция для обновления Линейного графика
    const updateLineChart = () => {
      destroyLineChart(); // Уничтожаем только Линейный график перед пересозданием

      const filteredLineChartVideos = filterVideosByDate(rawVideosData.value, lineChartDateRange.value);
      let usersDailyVideos = {};

      filteredLineChartVideos.forEach((video) => {
        const date = new Date(video.createTime).toISOString().split("T")[0];
        const userId = video.userId;

        if (!usersDailyVideos[date]) {
          usersDailyVideos[date] = {}; // Создаем пустой объект для даты
        }

        // Если пользователь уже есть для этой даты, увеличиваем количество его видео
        if (usersDailyVideos[date][userId]) {
          usersDailyVideos[date][userId]++;
        } else {
          // Если пользователь впервые появляется для этой даты
          usersDailyVideos[date][userId] = 1;
        }
      });

      // Подсчет пользователей по количеству видео в день
      let dailyUserVideoCounts = {};

      Object.keys(usersDailyVideos).forEach((date) => {
        dailyUserVideoCounts[date] = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, more: 0};

        Object.values(usersDailyVideos[date]).forEach((videoCount) => {
          if (videoCount === 1) {
            dailyUserVideoCounts[date][1]++;
          } else if (videoCount === 2) {
            dailyUserVideoCounts[date][2]++;
          } else if (videoCount === 3) {
            dailyUserVideoCounts[date][3]++;
          } else if (videoCount === 4) {
            dailyUserVideoCounts[date][4]++;
          } else if (videoCount === 5) {
            dailyUserVideoCounts[date][5]++;
          } else {
            dailyUserVideoCounts[date].more++;
          }
        });
      });

      // Данные для линейного графика
      const labels = Object.keys(dailyUserVideoCounts).sort();
      const lineChartData = {
        labels,
        datasets: [
          {
            label: "1 video",
            data: labels.map((date) => dailyUserVideoCounts[date][1]),
            borderColor: "rgba(75, 192, 192, 1)",
            fill: false,
          },
          {
            label: "2 videos",
            data: labels.map((date) => dailyUserVideoCounts[date][2]),
            borderColor: "rgba(54, 162, 235, 1)",
            fill: false,
          },
          {
            label: "3 videos",
            data: labels.map((date) => dailyUserVideoCounts[date][3]),
            borderColor: "rgba(255, 206, 86, 1)",
            fill: false,
          },
          {
            label: "4 videos",
            data: labels.map((date) => dailyUserVideoCounts[date][4]),
            borderColor: "rgba(153, 102, 255, 1)",
            fill: false,
          },
          {
            label: "5 videos",
            data: labels.map((date) => dailyUserVideoCounts[date][5]),
            borderColor: "rgba(255, 159, 64, 1)",
            fill: false,
          },
          {
            label: "More than 5 videos",
            data: labels.map((date) => dailyUserVideoCounts[date].more),
            borderColor: "rgba(255, 99, 132, 1)",
            fill: false,
          },
        ],
      };

      // Рендеринг линейного графика
      const lineCtx = document.getElementById("videosCountLineChart").getContext("2d");
      lineChartInstance = new Chart(lineCtx, {
        type: "line",
        data: lineChartData,
        options: {
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        },
      });
    };


    // Функция для обновления графика с динамикой публикаций обычных и скамных видео
    const updateScamVsNonScamChart = () => {
      destroyScamVsNonScamChart(); // Уничтожаем предыдущий график

      let scamVideosByDate = {};
      let nonScamVideosByDate = {};
      let nonVideosByDate = {}
      let filedVideosByDate = {}

      const filteredLineChartVideos = filterVideosByDate(rawVideosData.value, allLineChartDateRange.value, null, true);

      console.log(filteredLineChartVideos, 'filteredLineChartVideos')


      filteredLineChartVideos.forEach((video) => {
        const date = new Date(video.createTime).toISOString().split("T")[0];
        if (!scamVideosByDate[date]) {
          scamVideosByDate[date] = 0;
          nonScamVideosByDate[date] = 0;
          nonVideosByDate[date] = 0;
          filedVideosByDate[date] = 0;
        }

        if (video.is_scam) {
          scamVideosByDate[date]++;
        } else if(video.status !== 'PUBLISH_COMPLETE' && video.status !== 'FAILED') {
          nonVideosByDate[date]++;
        } else if(video.status === 'FAILED') {
          filedVideosByDate[date]++;
        } else {
          nonScamVideosByDate[date]++;
        }
      });

      // Данные для графика
      const labels = Object.keys(scamVideosByDate).sort();
      const scamVsNonScamChartData = {
        labels,
        datasets: [
          {
            label: "Non-scam videos",
            data: labels.map((date) => nonScamVideosByDate[date]),
            borderColor: "rgba(75, 192, 192, 1)",
            fill: false,
          },
          {
            label: "Scam videos",
            data: labels.map((date) => scamVideosByDate[date]),
            borderColor: "rgba(153, 102, 255, 1)",
            fill: false,
          },
          {
            label: "Non publish videos",
            data: labels.map((date) => nonVideosByDate[date]),
            borderColor: "rgba(255, 206, 86, 1)",
            fill: false,
          },
          {
            label: "Filed videos",
            data: labels.map((date) => filedVideosByDate[date]),
            borderColor: "rgba(255, 99, 132, 1)",
            fill: false,
          },
        ],
      };

      // Рендеринг графика
      const scamVsNonScamCtx = document.getElementById("scamVsNonScamChart").getContext("2d");
      scamVsNonScamChartInstance = new Chart(scamVsNonScamCtx, {
        type: "line",
        data: scamVsNonScamChartData,
        options: {
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    // Уничтожение графиков при демонтировании компонента
    onBeforeUnmount(() => {
      destroyBarChart();
      destroyLineChart();
      destroyScamVsNonScamChart();
    });

    // Запуск функции получения данных при монтировании компонента
    onMounted(() => {
      fetchData();
    });

    return {
      filteredStatistics,
      dateRangeForStats,
      barChartDateRange,
      lineChartDateRange,
      applyFilters,
      allLineChartDateRange,
      loading,
      videoTypeFilter,
    };
  },
};
</script>

<style scoped>
.statistic {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.static-li {
  margin-right: 20px;
  margin-bottom: 10px;
}

.filter-section {
  margin-bottom: 20px;
}

.preview-header {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

span {
  color: #fff;
  opacity: 0.6;
  font-family: Roboto;
}

strong {
  color: #fff;
  font-family: Roboto;
  margin-top: 10px;
}

.static-li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  background: rgb(21, 23, 38);
}

.statistic {
  display: flex;
  margin-top: 14px;
}

.link-color {
  color: #fff;
  text-decoration: underline !important;
}

.description {
  margin-bottom: 8px;
}

.download-csv {
  color: #fff;
  margin-bottom: 8px;
  cursor: pointer;
}

.line-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scam-alert {
  background: red;
  color: #fff !important;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  opacity: 1;
  font-size: 12px;
}

.barChart {
  max-height: 340px;
  margin-top: 16px;
}

.videosChart {
  max-height: 280px;
  margin-top: 16px;
}
</style>
