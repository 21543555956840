<template>
  <div class="task-view-page" v-loading="loading">
    <div class="users-stat">
      <h3>{{ profile.username }}</h3>

      <div class="statistic">
        <div class="static-li">
          <strong> {{ profile.video_count || '-' }}</strong>
          <span>{{$t('video_count')}}</span>
        </div>
        <div class="static-li">
          <strong>{{ profile.likes_count || '-' }}</strong>
          <span>{{$t('total_likes')}}</span>

        </div>
        <div class="static-li">
          <strong>{{ profile.balance || '-' }}</strong>
          <span>{{$t('balance')}}</span>

        </div>
        <div class="static-li">
          <strong>{{ profile.follower_count || '-' }}</strong>
          <span>{{$t('followers')}}</span>

        </div>
        <div class="static-li">
          <strong>{{ profile.following_count || '-' }}</strong>
          <span>{{$t('following')}}</span>

        </div>
      </div>

      <el-table class="dark-theme" :data="videos" style="width: 100%; margin-top: 20px">
        <el-table-column
            label="Preview"
            v-slot="{ row }"
        >
          <a  v-if="row.task" :href="`/admin/task/${row.task.id}`">{{row.task.title}}</a>
        </el-table-column>

        <el-table-column prop="share_count" label="Shares"></el-table-column>
        <el-table-column  prop="like_count" label="Likes"></el-table-column>
        <el-table-column  sortable prop="view_count" label="Views"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
// import {db} from '@/firebase/init';
// import {collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';
// import {ElMessageBox} from "element-plus";
import {useRoute} from "vue-router";
// import Papa from 'papaparse';
import {fetchTikTokProfilesByUsername} from "@/firebase/queries";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "@/firebase/init";

export default {
  setup() {
    const profile = ref({});
    const route = useRoute();
    const videos = ref([])
    const loading = ref(false)

    const fetchProfile = async () => {
      const p = await fetchTikTokProfilesByUsername(route.params.id)
      console.log(p)
      profile.value = p


      const q = query(collection(db, 'videos'), where('userId', '==',  p.userUid));
      const queryVideosSnapshot = await getDocs(q);
      const videosQuery = queryVideosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter((d) => d.like_count !== undefined);

      const queryTasksSnapshot = await getDocs(collection(db, "tasks"));
      const tasks  = queryTasksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));


      videosQuery.forEach((video, key) => {
        videosQuery[key].task = tasks.find(({id}) => video.offerId === id)
      })

      console.log(videosQuery, 'videosQuery')
      videos.value = videosQuery;

    }

    // const fetchTasks = async () => {
    //   const querySnapshot = await getDocs(collection(db, "tasks"));
    //   tasks.value = querySnapshot.docs.map(doc => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));
    // };

    onMounted(fetchProfile);

    return {loading, videos, profile};
  },
};
</script>

<style scoped lang="scss">
.preview-header {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

span {
  color: #fff;
  opacity: 0.6;
  font-family: Roboto;
}

strong {
  color: #fff;
  font-family: Roboto;
  margin-top: 10px;
}

.static-li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  background: rgb(21, 23, 38);
}

.statistic {
  display: flex;
  margin-top: 14px;
}

.link-color {
  color: #fff;
  text-decoration: underline !important;
}

.description {
  margin-bottom: 8px;
}

.download-csv {
  color: #fff;
  cursor: pointer;
}

.line-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
