<template>
  <div class="user-profile" v-loading="loader">
    <img src="@/assets/tiktok-logo.svg" class="tiktok-logo">
    <h2>{{$t('connect_tiktok.title')}}</h2>
    <div class="container-center">
      <div @click="connectAccount" v-if="!loader">
        <button class="btn-solid connect-tiktok-user">{{$t('connect_tiktok.btn')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref} from "vue";
import {fetchTikTokProfiles} from "@/firebase/queries";
import {getAuth} from 'firebase/auth';
import axios from "@/plugins/axios";
import {useRouter} from "vue-router";


export default {
  name: "ConnectTiktTok",
  setup() {
    const tasks = ref([]);
    const profiles = ref([]);
    const loader = ref(true)
    const router = useRouter();
    const auth = getAuth();
    const user = auth.currentUser;

    const connectAccount = async () => {
      const token = await user.getIdToken();
      axios.post('/tiktok-hash', {token}).then(({data}) => {
        window.location.href = process.env.VUE_APP_TIKTOK_URL + '/tiktok-init/?hash=' + data
      })
    }


    onMounted(async () => {
      loader.value = true
      const userUid = user.uid;
      const prof = await fetchTikTokProfiles(userUid);

      if(prof && prof.length) {
        const afterLogin = localStorage.getItem('redirectAfterLogin');

        if(afterLogin) {
          localStorage.removeItem('redirectAfterLogin')
          router.push(afterLogin)
        } else {
          router.push('/offers')
        }
      }
      profiles.value = prof
      loader.value = false
    });

    return {tasks, profiles, loader, connectAccount};
  }
}
</script>

<style scoped lang="scss">
.user-profile {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  flex-direction: column;

  h2 {
    color: #fff;
    text-align: center;
  }
}

.user-title {
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
}

.offer-description, .offer-label {
  display: flex;
  font-size: 17px;
  color: #eee;
  font-weight: 300;
  font-family: Roboto;
}

.offer-participants {
  max-width: 502px;
  width: calc(100% - 16px);
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-info {
  margin-right: 8px;
}

.participants-count {
  color: #fff;
  font-weight: 600;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-family: Roboto;
}

.icon-info.hot {
  width: 28px;
  height: 28px;
  margin-right: 4px;
  border-radius: 50%;
}

.btn-container {
  width: 120px;
  margin-left: 32px;
}

.offers-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 16px;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 260px;
}

.stats-profile {
  display: flex;
  justify-content: flex-start;
  color: #fff;


  .stat-col {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.08);
    min-width: 120px;
    border-radius: 10px;
    margin-right: 8px;
    padding: 10px 10px;
    margin-top: 24px;
    margin-bottom: 18px;

    span {
      font-size: 14px;
      font-weight: 300;
      font-family: Roboto;
    }

    strong {
      font-size: 20px;
      font-weight: 600;
      font-family: Roboto;
    }
  }
}

@media only screen and (max-width: 600px) {
  .stats-profile {
    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 8px 8px;
    margin-top: 0px;
    margin-bottom: 42px;
    .stat-col {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }

  .btn-container {
    width: 100px;
  }

  .offer-participants {
    width: 100%;
    max-width: 100%;
  }
  .offer-label {
    font-size: 15px;
  }

  .user-title {
    margin-bottom: 16px;
  }

  .offers-cards {
    display: flex;
    flex-direction: column;
  }

  .user-profile {
    padding: 0 16px;
  }
}

.participants-count-new {
  color: #fff;
  font-weight: 600;
  align-items: center;
  display: flex;
  margin-top: 4px;
  padding: 4px 16px;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  background: rgba(255, 255, 255, 0.08);
  width: 100%;
  display: flex;
  flex: 1;
  font-family: Roboto;
}

.balances {
  display: flex;
  flex-direction: column;

  img {
    max-width: 26px;
  }
}

.tiktok-logo {
  height: 300px;
}

</style>
