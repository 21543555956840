<template>
  <div :class="['language', {isMobile}]" >
    <el-select
        v-model="currentLanguage"
        clearable
        style="width: 140px"
    >
      <el-option
          v-for="lang in languages"
          :key="lang.code"
          :label="lang.label"
          :value="lang.code"
      >
        {{ lang.label }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export default {
  props: {
    isMobile: Boolean
  },
  setup() {
    const { locale } = useI18n();

    const languages = [
      { code: 'en', label: 'English' },
      { code: 'pt', label: 'Português' },
      { code: 'es', label: 'Español' },
    ];

    // Используем вычисляемое свойство для привязки к v-model
    const currentLanguage = computed({
      get: () => locale.value,
      set: (val) => {
        if(!val) {
          localStorage.setItem('local', 'en')
          locale.value = 'en';
        } else {
          localStorage.setItem('local', val)
          locale.value = val;
        }
      },
    });

    return {
      currentLanguage,
      languages,
    };
  },
};
</script>

<style lang="scss">
.language {
  padding: 0 8px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  .el-select__wrapper {
    height: 42px !important;
  }

}
select {
  color: #fff;
  outline: none;
  background: rgba(0,0,0,0);
  border: none;
}

.isMobile {
  .el-select__wrapper {
    height: 42px !important;
  }
}

@media only screen and (max-width: 600px) {

  .isMobile {
    .el-select__wrapper {
      height: 36px !important;
    }
  }
}


/* Вы можете добавить стили для компонента выбора языка здесь */
</style>
