<template>
  <div>
    <div class="off-er">
      <el-carousel style="width: 100%;  max-width: 1024px;" :interval="5000" :height="carouselHeight">
        <el-carousel-item v-for="(offer, index) in offers" :key="index">
          <div class="offer-preview" :style="{ 'background-image': 'url(' + offer.image + ')' }">
            <div class="text-offer">
              <h3>{{ $t(`header_offers[${index}].title`) }}</h3>
              <h5>{{$t(`header_offers[${index}].subtitle`)  }}</h5>
              <a
                  v-if="offer.link"
                  data-tag
                  data-cat="user-action" data-label="how-it-works"
                  :href="offer.link" target="_blank" :class="`btn header-banner-btn-${index}`" style="margin-top: 20px; font-size: 18px">{{$t(`header_offers[${index}].linkTxt`)  }}</a>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- Остальная часть вашего шаблона остается без изменений -->

    <div class="offers">
      <div class="title-offers">
        <h2 class="title" style="margin-bottom: 32px">{{ $t('all_offers') }}</h2>
      </div>

      <div class="filters">
        <el-select style="max-width: 200px" v-model="selectedCurrency" :placeholder="$t('select_currency')" clearable>
          <el-option v-for="currency in uniqueCurrencies" :key="currency" :label="currency" :value="currency"/>
        </el-select>

        <div @click="togglePriceSort" class="sort-price">
          <span style="margin-right: 8px">{{$t('price')}}</span>
          <el-icon v-if="priceSortOrder === 'desc'"><CaretBottom /></el-icon>
          <el-icon  v-if="priceSortOrder === 'asc'"><CaretTop /></el-icon>
          <el-icon  v-if="priceSortOrder === null"><DCaret /></el-icon>

        </div>
      </div>
      <div class="offers-cards">
        <OfferCard
            v-for="task in filteredAndSortedTasks" :key="task.id" :card="{ ...task }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OfferCard from "@/components/OfferCard";
import {onMounted, ref, computed, onUnmounted} from 'vue';
import { db } from '@/firebase/init';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { orderBy } from "lodash";
import { ElCarousel, ElCarouselItem } from 'element-plus';

export default {
  name: "OffersPage",
  components: {
    OfferCard,
    ElCarousel,
    ElCarouselItem
  },
  setup() {
    const tasks = ref([]);
    const selectedCurrency = ref("");
    const priceSortOrder = ref(null);

    const carouselHeight = ref(window.innerWidth < 600 ? '290px' : '443px');

    const updateCarouselHeight = () => {
      carouselHeight.value = window.innerWidth < 600 ? '290px' : '443px';
    };

    onMounted(() => {
      window.addEventListener('resize', updateCarouselHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateCarouselHeight);
    });

    const offers = ref([
      {
        i: 0,
        image: '/12.jpg',
        link: 'https://telegra.ph/How-to-get-started-StreamSalexyz-09-27',
      },
      {
        i: 1,
        image: '/2.jpg',
        link: 'https://streamsale.xyz/referral',
      },
      {
        i: 2,
        image: '/3.jpg',
        link: 'https://streamsale.xyz//offer/ZJKwVGR0mVCfQ2XNmUbZ',
      },
    ]);

    const uniqueCurrencies = computed(() => {
      const currencies = tasks.value.map(task => task.coin?.name || "");
      return [...new Set(currencies)].filter(currency => currency);
    });

    const fetchTasks = async () => {
      const q = query(collection(db, "tasks"), where("hide", "!=", true));
      const querySnapshot = await getDocs(q);
      tasks.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    };

    onMounted(fetchTasks);

    const filteredAndSortedTasks = computed(() => {
      let filteredTasks = tasks.value;

      if (selectedCurrency.value) {
        filteredTasks = filteredTasks.filter(task => task.coin?.name === selectedCurrency.value);
      }

      if(priceSortOrder.value) {
        return orderBy(filteredTasks, ['price'], [priceSortOrder.value]);
      } else {
        return orderBy(filteredTasks, ['order'], ['asc']);

      }
    });

    const togglePriceSort = () => {
      if(priceSortOrder.value === null) {
        priceSortOrder.value = 'asc'
      } else if(priceSortOrder.value === 'asc') {
        priceSortOrder.value = 'desc'
      } else {
        priceSortOrder.value = null
      }
    };

    return {
      tasks,
      selectedCurrency,
      priceSortOrder,
      filteredAndSortedTasks,
      uniqueCurrencies,
      togglePriceSort,
      carouselHeight,
      offers
    };
  },
};
</script>

<style scoped lang="scss">
.title-offers {

  display: flex;
  justify-content: flex-start;
  max-width: 1024px;
  margin: 32px auto 0 auto;
  width: calc(100% - 16px);
}

.offers {
  max-width: 1024px;
  margin: 32px auto;
  min-height: 420px;
  width: calc(100% - 16px);
}

.offers-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 16px;
}

@media only screen and (max-width: 600px) {
  .offers-cards {
    display: flex;
    flex-direction: column;
    max-width: 440px;
    width: calc(100% - 16px);
    margin: 0 auto;
  }
}

.offer-preview {
  width: 100%;
  height: 420px;
  max-height: 432px;
  object-fit: cover;
  border-radius: 20px;
  max-width: 1024px;
  background-size: cover;
  background-position: center center;
  margin: 16px auto 32px auto;
  display: flex;
  flex-direction: column;
  padding: 72px;

  h3 {
    font-size: 42px;
    font-weight: bold;
  }

  h5 {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
  }
}

.text-offer {
  width: 60%;
}

.off-er {
  display: flex;
  justify-content: center;
}


@media only screen and (max-width: 600px) {
  .offer-preview {
    max-width: 1024px;
    border-radius: 0 !important;
    padding: 16px;
    height: 290px;
    background-position: center center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0 !important;

    h3 {
      font-size: 24px;
      max-width: 90%;
    }

    h5 {
      font-size: 16px;
      max-width: 80%;
    }

    .btn {
      padding: 16px 32px;
    }
  }

  .text-offer {
    width: 80%;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  margin-top: -18px;
  flex: 1;
}

.sort-price {
  height: 48px;
  color: #fff;
  display: flex;
  background: rgba(255, 255, 255, 0.15) !important;
  border-radius: 20px !important;
  height: 48px !important;
  font-size: 16px !important;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
