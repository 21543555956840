<template>
  <div class="offer-card">
    <div class="offer-container">
      <div class="offer-preview-img">
      <img class="offer-preview" :src="card.preview"/>
      </div>
      <div class="offer-body">
        <div class="offer-title-container">
          <div class="offer-title">{{ card.title }}</div>
        </div>
        <div class="container-description">
          <span class="offer-description" v-html="formattedDescription"></span>
        </div>
<!--        <div class="offer-participants">-->
<!--          <span class="offer-label">{{$t('company_budget')}}</span>-->
<!--          <div class="participants-count">-->
<!--            <img  v-if="!card?.coin" class="hot-participant" src="@/assets/usdt.svg">-->
<!--            <img v-else  class="hot-participant" :src="card?.coin?.preview">-->

<!--            <strong>{{ card.companyBudget }}  {{card?.coin?.name || 'POINT'}}</strong></div>-->
<!--        </div>-->

        <div class="offer-footer">
          <div class="price-offer-container">
            <div class="offer-amount">
              <img v-if="!card?.coin" class="usdt-offer" src="@/assets/usdt.svg">
              <img v-else class="usdt-offer" :src="card?.coin?.preview">

              <span>{{ card.price }} {{card?.coin?.name || 'POINT'}}</span>
            </div>
            <span class="small">{{$t('per_1000_views')}}</span>
          </div>
          <router-link :to="`/offer/${card.id}`"
                       data-tag
                       data-cat="user-action" data-label="offer-card-click" :data-value="card.title"
          >
            <button class="btn-solid view-offer-card-main">{{$t('view_offer')}}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferCard",
  props: {
    card: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        price: 0,
        currentUsers: 0,
        maxUsers: 0
      })
    }
  },
  computed: {
    formattedDescription() {
      console.log(this.card, 'card')
      if (!this.card || !this.card.description) return '';
      // eslint-disable
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; // eslint-disable-line
      return this.card.description.replace(urlPattern, '<a class="link-description" href="$1" target="_blank">$1</a>');
    }
  }
}
</script>

<style scoped lang="scss">
.offer-container, .container-description {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.offer-card {
  max-width: 100%;
  overflow: hidden;
  border-radius: 18px;
  padding-bottom: 12px;
  background: rgba(255, 255, 255, 0.08);

  .offer-preview {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .offer-title {
    color: #fff;
    font-size: 18px;
    max-width: 80%;
    font-family: Roboto;
    font-weight: 600;
  }

  .offer-title-container {
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 12px;
    box-sizing: border-box;
    display: flex;
    margin-top: -101px;
    height: 100px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  .offer-body {
    padding: 0 12px 4px 12px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
  }

  .offer-description, .offer-label {
    display: inline;
    font-size: 14px;
    color: #eee;
    font-weight: 300;
    font-family: Roboto;
  }

  .offer-description {
    margin-top: 20px;
    margin-bottom: 8px;
    white-space: pre-wrap;
  }


  .offer-participants {
    padding: 12px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .participants-count {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    font-family: Roboto;
  }

  .offer-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 16px;
    margin-top: 16px;

    .btn-solid {
      font-size: 14px;
      padding-left: 12px;
      width: 100%;
      padding-right: 12px;
      height: 48px;
      cursor: pointer;
    }
  }


  .offer-amount {
    color: #fff;
    display: flex;
    align-items: center;
  }

  .hot-participant {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }

  .usdt-offer {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
}

.participants-count {
  display: flex;
  align-items: center;
}

.small {
  font-size: 12px;
  font-weight: 300;
  font-family: Roboto;
  margin-left: 28px;
  color: #fff;
}

.price-offer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
</style>
