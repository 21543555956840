<template>
  <div class="header-main">
    <header id="header" class="header-layout1">
      <div :id="'sticky-header'" :class="{ 'menu-area transparent-header': true, 'sticky-menu': sticky }">
        <div class="container custom-container">
          <div class="row">
            <div class="col-12">
              <div class="menu-wrap">
                <nav class="menu-nav">
                  <div class="logo">
                    <router-link to="/">
                      <img src="@/assets/logo.svg" alt="Logo"/>
                    </router-link>
                  </div>
                  <div class="navbar-wrap main-menu d-none d-lg-flex">
                    <NavMenuTwo  v-if="userEmail"/>
                  </div>
                  <div class="header-action" v-if="userEmail">
                    <LanguageSwitcher isMobile/>
                  </div>
                  <div v-if="!userEmail" style="display: flex">
                    <LanguageSwitcher isMobile/>
                  <router-link
                      data-tag data-cat="navigate" data-label="register" data-value="header"
                      style="height: 42px; font-size: 16px !important; margin-left: 8px; padding: 0 18px" class="btn btn-desk" v-if="!userEmail" to="/login">
                    {{$t('sign-in')}}
                  </router-link>
                  </div>
                  <div v-if="userEmail" @click="setIsActive(true)" class="mobile-nav-toggler">
                    <i class="fas fa-bars"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Sidebar :isUserEmail="!!userEmail" :isActive="isActive" :setIsActive="setIsActive"/>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import NavMenuTwo from './NavMenuTwo';
import Sidebar from './SidebarMenu';
import LanguageSwitcher from "@/components/LanguageSwitcher";
import {getAuth, onAuthStateChanged} from "firebase/auth";

export default {
  name: 'HeaderTwo',
  components: {
    LanguageSwitcher,
    NavMenuTwo,
    Sidebar
  },
  props: {
    msg: String
  },
  setup() {
    const isActive = ref(false);

    const setIsActive = (value) => {
      isActive.value = value;
    };

    const auth = getAuth();
    const userEmail = ref('');


    onMounted(() => {
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          userEmail.value = authUser.email;
        }
      });
    });


    return {
      isActive,
      userEmail,
      setIsActive
    };
  }
};
</script>
<style lang="scss" scoped>
.header-main {
  height: 98px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.btn::after {
  margin-left: 8px;
}

@media only screen and (max-width: 600px) {
  .btn-desk {
    display: none;
  }
}
</style>
