import { createI18n } from 'vue-i18n';

import en from '../locales/en.json';
import pt from '../locales/pt.json';
import es from '../locales/es.json';

const messages = {
    en,
    pt,
    es,
};

// Список доступных локалей
const availableLocales = ['en', 'pt', 'es'];

let locale = 'en'; // значение по умолчанию

const saveLocal = localStorage.getItem('local')

if(saveLocal && availableLocales.includes(saveLocal)) {
    locale = saveLocal
} else if (typeof navigator !== 'undefined') {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage) {
        // Извлекаем первые две буквы языка
        const languageCode = browserLanguage.substring(0, 2);
        if (availableLocales.includes(languageCode)) {
            locale = languageCode;
        }
    }
}

const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages,
});

export default i18n;
