import {getFirestore, collection, query, where, getDocs, doc, getDoc} from 'firebase/firestore';

const db = getFirestore();
export async function fetchTasksForUser(userUid) {
    const tasksColRef = collection(db, "videos");
    const q = query(tasksColRef, where("userUid", "==", userUid));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));
}


export async function fetchTikTokProfiles(userUid) {
    const profilesColRef = collection(db, "profiles");
    const q = query(profilesColRef, where("userUid", "==", userUid));
    const querySnapshot = await getDocs(q);

    const result = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    return result;
}

export async function getBalances(userUid) {
    const balanceDocRef = doc(db, "balances", userUid);
    const balanceDocSnapshot = await getDoc(balanceDocRef);

    if (balanceDocSnapshot.exists()) {
        return  balanceDocSnapshot.data()
    } else {
       return null;
    }
}

export async function getWithdrawal(userUid) {
    const balanceDocRef = doc(db, "withdrawal", userUid);
    const balanceDocSnapshot = await getDoc(balanceDocRef);

    if (balanceDocSnapshot.exists()) {
        return  balanceDocSnapshot.data()
    } else {
        return null;
    }
}

export async function fetchTikTokProfilesByUsername(username) {
    const profilesColRef = collection(db, "profiles");
    const q = query(profilesColRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);

    const result = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

    console.log(result, 'result')

    return  result && result[0];
}
