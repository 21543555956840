<template>
  <div class="admin-page">
    <div class="create-offer-container">
      <div class="filter-container" style="margin-top: 18px">
        <input
            class="main-input"
            v-model="searchTitle"
            placeholder="Search by title"
            style="width: 260px; margin-right: 20px; height: 46px"
        />
        <el-select
            v-model="selectedCurrencyType"
            placeholder="Select currency type"
            clearable
            style="width: 200px"
            @change="filterCoinsByType"
        >
          <el-option label="USDT" value="usdt"></el-option>
          <el-option label="Fiat" value="fiat"></el-option>
          <el-option label="Token" value="token"></el-option>
        </el-select>
      </div>


      <router-link to="/admin/create-task">
        <button class="btn-main">{{$t('create_offer')}}</button>
      </router-link>
    </div>

    <div style="margin-top: 18px">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="Active Tasks" name="active"></el-tab-pane>
        <el-tab-pane label="Hidden Tasks" name="hidden"></el-tab-pane>
      </el-tabs>

      <el-table class="dark-theme" :data="filteredTasks" style="width: 100%">
        <el-table-column
            label="Preview"
            v-slot="{ row }"
        >
          <img :src="row.preview" alt="preview" style="width: 50px; height: 50px">
        </el-table-column>
        <el-table-column prop="title" label="Title"></el-table-column>
        <el-table-column prop="description" label="Description"></el-table-column>
        <el-table-column sortable prop="geo" label="Geo"></el-table-column>
        <el-table-column sortable prop="price" label="Price"></el-table-column>
        <el-table-column sortable prop="coin.name" label="Coin"></el-table-column>

        <el-table-column sortable prop="companyBudget" label="Company budget"></el-table-column>
        <el-table-column label="Hide" width="180">
          <template #default="scope">
            <el-switch
                v-model="scope.row.hide"
                @change="toggleHide(scope.row)"
                active-text="Hidden"
                inactive-text="Visible"
            />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Operations" width="200">
          <template #default="scope">
            <router-link
                :to="'/admin/view-offer/' + scope.row.id"
                class="router-link"
            >
              <i class="fas fa-eye"></i>
            </router-link>
            <router-link
                :to="'/admin/task/' + scope.row.id"
                class="router-link"
            >
              <el-button
                  type="default"
              >{{$t('view')}}</el-button>
            </router-link>
            <el-button
                type="primary"
                @click.prevent="editRow(scope.row.id)"
            >{{$t('edit')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<script>
import { onMounted, ref, computed } from 'vue';
import { db } from '@/firebase/init';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";

export default {
  setup() {
    const tasks = ref([]);
    const coins = ref([]); // Для хранения всех монет
    const activeTab = ref('active');
    const searchTitle = ref(''); // Поле для поиска по заголовку
    const selectedCurrencyType = ref(''); // Поле для фильтрации по типу валюты
    const filteredCoins = ref([]); // Монеты, отфильтрованные по типу валюты
    const router = useRouter();

    // Получаем задачи из базы данных
    const fetchTasks = async () => {
      const querySnapshot = await getDocs(collection(db, "tasks"));
      tasks.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    // Получаем все монеты из коллекции coins
    const fetchCoins = async () => {
      const querySnapshot = await getDocs(collection(db, "coins"));
      coins.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    // Фильтруем монеты по выбранному типу валюты
    const filterCoinsByType = () => {
      if (selectedCurrencyType.value) {
        filteredCoins.value = coins.value.filter(coin => coin.currencyType === selectedCurrencyType.value);
      } else {
        filteredCoins.value = coins.value;
      }
    };

    // Загружаем данные при монтировании компонента
    onMounted(async () => {
      await fetchCoins(); // Сначала загружаем монеты
      await fetchTasks(); // Затем задачи
    });

    // Фильтруем задачи по поиску, типу валюты и статусу (active/hidden)
    const filteredTasks = computed(() => {
      let filtered = tasks.value;

      // Фильтрация по активным и скрытым задачам
      if (activeTab.value === 'active') {
        filtered = filtered.filter(task => task.hide === false);
      } else if (activeTab.value === 'hidden') {
        filtered = filtered.filter(task => task.hide === true);
      }

      // Фильтрация по заголовку
      if (searchTitle.value) {
        filtered = filtered.filter(task =>
            task.title.toLowerCase().includes(searchTitle.value.toLowerCase())
        );
      }

      // Фильтрация по типу валюты через символ монеты
      if (filteredCoins.value.length > 0) {
        const filteredSymbols = filteredCoins.value.map(coin => coin.symbol);
        filtered = filtered.filter(task => task.coin && filteredSymbols.includes(task.coin.symbol));
      }

      return filtered;
    });

    const handleTabClick = (tab) => {
      activeTab.value = tab.name;
    };

    const toggleHide = async (task) => {
      const taskRef = doc(db, "tasks", task.id);
      await updateDoc(taskRef, { hide: task.hide });
    };

    const editRow = async (id) => {
      ElMessageBox.confirm(
          'You are editing a published task. Please ensure that any changes are minimal and that the new conditions closely resemble the original ones. Continue?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      )
          .then(() => {
            router.push('/admin/edit-task/' + id);
          })
          .catch(() => {
            // Handle cancel action
          });
    };

    return { tasks, coins, activeTab, filteredTasks, searchTitle, selectedCurrencyType, filteredCoins, handleTabClick, toggleHide, editRow, filterCoinsByType };
  },
};
</script>

<style lang="scss" scoped>
.admin-page {
  font-family: Roboto;
  .create-offer-container {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
  }

  .el-table {
    border-radius: 20px;
  }

  .el-table thead {
    color: #ffffff !important;
    height: 64px !important;
  }

  .el-table__row {
    cursor: pointer;
  }
}

.link-color {
  color: #fff;
  text-decoration: underline !important;
}

.router-link {
  margin-right: 12px;
}
</style>
